import { useCallback } from 'react'
import Marker from '@/ui/icons/Marker'
import House from '@/ui/icons/House'
import BriefCaseIcon from '@/ui/icons/BriefCaseIcon'

export const renderIcon = item => {
    if (item === 'WORK') return <BriefCaseIcon />
    else if (item === 'HOME') return <House />
    else return <Marker />
}

const AddressBoxView = ({ addressType, addressCode, item, selected, onClick }) => {
    const changeAddress = useCallback(() => {
        onClick(item)
    }, [onClick, addressType])

    return (
        <div
            onClick={changeAddress}
            className={`${
                selected ? 'bg-gray-300' : 'bg-gray-50'
            } p-3 items-center flex flex-col justify-center border w-[90px] h-[90px] rounded-xl  hover:bg-gray-300 hover:cursor-pointer`}
        >
            <div className={'pb-3'}>{renderIcon(addressType)}</div>
            <span>{addressCode}</span>
        </div>
    )
}

export default AddressBoxView
