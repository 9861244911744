const SvgComponent = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none" {...props}>
        <path
            fill="#E30F1B"
            d="M28.121 9.806c-1.133-1.253-3.026-1.88-5.773-1.88h-.32v-.053c0-2.24 0-5.014-5.013-5.014h-2.027c-5.013 0-5.013 2.787-5.013 5.014v.066h-.32c-2.76 0-4.64.627-5.774 1.88-1.32 1.467-1.28 3.44-1.146 4.787l.013.093.103 1.085c.02.2.127.38.295.49.32.209.855.554 1.175.732.187.12.387.227.587.333a22.885 22.885 0 0 0 7.333 2.507c.12 1.253.667 2.72 3.587 2.72s3.493-1.453 3.587-2.747a22.438 22.438 0 0 0 7.72-2.773c.08-.04.133-.08.2-.12a17.535 17.535 0 0 0 1.578-1.028.658.658 0 0 0 .267-.462l.021-.19.067-.627c.013-.08.013-.146.027-.24.106-1.346.08-3.186-1.174-4.573Zm-10.666 9.133c0 1.414 0 1.627-1.64 1.627s-1.64-.253-1.64-1.613v-1.68h3.28v1.666ZM11.88 7.926v-.053c0-2.267 0-3.107 3.107-3.107h2.027c3.106 0 3.106.853 3.106 3.107v.066h-8.24v-.013Z"
        />
        <path
            fill="#E30F1B"
            d="M27.831 18.813a.678.678 0 0 1 .967.67l-.478 5.271c-.28 2.667-1.374 5.387-7.24 5.387H10.92c-5.867 0-6.96-2.72-7.24-5.373l-.454-4.997a.677.677 0 0 1 .954-.675c1.519.688 4.323 1.907 6.055 2.36a.76.76 0 0 1 .492.42c.81 1.73 2.565 2.652 5.1 2.652 2.509 0 4.286-.957 5.098-2.692a.762.762 0 0 1 .493-.42c1.84-.483 4.824-1.847 6.413-2.603Z"
        />
    </svg>
)
export default SvgComponent
