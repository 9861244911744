const SquaresPattern = props => {
    const color = !props.color ? '#215929' : props.color
    const svgClass = props.className !== undefined ? props.className : null
    const patternId = 'squares' + Math.floor(Math.random() * 1000000000)

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="73" className={svgClass}>
            <defs>
                <pattern id={patternId} patternUnits="userSpaceOnUse" width="49" height="73" x="50%">
                    <path
                        d="M0 48.667V73h24.333V48.667H0ZM24.333 24.333v24.334h24.334V24.333H24.333ZM0 0v24.333h24.333V0H0Z"
                        fill={color}
                    />
                </pattern>
            </defs>
            <rect width="100%" height="100%" fill={`url(#${patternId})`} />
        </svg>
    )
}

export default SquaresPattern
